import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import AdminGuard from 'src/components/AdminGuard';
import GuestGuard from 'src/components/GuestGuard';
import AdminOrBorrowerGuard from 'src/components/AdminOrBorrowerGuard'

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/app/about',
    component: lazy(() => import('src/views/lenders/About'))
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/app/my-bids',
    component: lazy(() => import('src/views/lenders/Bids'))
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/app/my-p2p-account',
    component: lazy(() => import('src/views/lenders/Accounts'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot',
    component: lazy(() => import('src/views/auth/ForgotView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/reset-password',
    component: lazy(() => import('src/views/auth/ResetPassword'))
  },
  {
    exact: true,
    path: '/auth/callback',
    component: lazy(() => import('src/views/auth/AuthCallback'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },

  {
    exact: true,
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/app/account',
    component: lazy(() => import('src/views/account/AccountView'))
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/app/dashboard',
    component: lazy(() => import('src/views/dashboard'))
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/app',
    component: () => <Redirect to="/app/dashboard" />
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/app/promNotes',
    component: lazy(() => import('src/views/promNotes/ListView'))
  },

  {
    exact: true,
    guard: AdminOrBorrowerGuard,
    layout: DashboardLayout,
    path: '/app/promNotes/create',
    component: lazy(() => import('src/views/promNotes/CreateView'))
  },
  {
    exact: true,
    guard: AdminOrBorrowerGuard,
    layout: DashboardLayout,
    path: '/app/promNotes/:id/contract',
    component: lazy(() => import('src/views/promNotes/Contract'))
  },
  {
    exact: true,
    guard: AdminOrBorrowerGuard,
    layout: DashboardLayout,
    path: '/app/promNotes/:id/edit',
    component: lazy(() => import('src/views/promNotes/EditView'))
  },
  {
    exact: true,
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/app/promNotes/:id',
    component: lazy(() => import('src/views/promNotes/DetailsView'))
  },



  {
    path: '/app',
    guard: AdminGuard,
    layout: DashboardLayout,
    routes: [

      {
        exact: true,
        path: '/app/lenders',
        component: lazy(() => import('src/views/lenders/ListView'))
      },
      {
        exact: true,
        path: '/app/lenders/create',
        component: lazy(() => import('src/views/lenders/CreateView'))
      },
      {
        exact: true,
        path: '/app/lenders/:id/edit',
        component: lazy(() => import('src/views/lenders/EditView'))
      },
      {
        exact: true,
        path: '/app/lenders/:id',
        component: lazy(() => import('src/views/lenders/DetailsView'))
      },
      {
        exact: true,
        path: '/app/borrowers',
        component: lazy(() => import('src/views/borrowers/ListView'))
      },
      {
        exact: true,
        path: '/app/borrowers/create',
        component: lazy(() => import('src/views/borrowers/CreateView'))
      },
      {
        exact: true,
        path: '/app/borrowers/:id/edit',
        component: lazy(() => import('src/views/borrowers/EditView'))
      },

      {
        exact: true,
        path: '/app/borrowers/:id',
        component: lazy(() => import('src/views/borrowers/DetailsView'))
      },

      {
        exact: true,
        path: '/app/adminUsers',
        component: lazy(() => import('src/views/adminUsers/ListView'))
      },
      {
        exact: true,
        path: '/app/adminUsers/create',
        component: lazy(() => import('src/views/adminUsers/CreateView'))
      },
      {
        exact: true,
        path: '/app/adminUsers/:id',
        component: lazy(() => import('src/views/adminUsers/EditView'))
      },
      {
        exact: true,
        path: '/app/terms',
        component: lazy(() => import('src/views/terms/ListView'))
      },
      {
        exact: true,
        path: '/app/terms/create',
        component: lazy(() => import('src/views/terms/CreateView'))
      },
      {
        exact: true,
        path: '/app/terms/:id',
        component: lazy(() => import('src/views/terms/EditView'))
      },
      {
        exact: true,
        path: '/app/transactions',
        component: lazy(() => import('src/views/transactions/ListView'))
      },
      {
        exact: true,
        path: '/app/transactions/create',
        component: lazy(() => import('src/views/transactions/CreateView'))
      },
      // {
      //   exact: true,
      //   path: '/app/transactions/:id',
      //   component: lazy(() => import('src/views/transactions/EditView'))
      // },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/app" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
