import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const AdminOrBorrowerGuard = ({ children }) => {
  const { isAdmin, isBorrower } = useAuth();
  if (!isAdmin() && !isBorrower()) {
    return <Redirect to="/app/dashboard" />;
  }

  return (
    <>
      {children}
    </>
  );
};

AdminOrBorrowerGuard.propTypes = {
  children: PropTypes.node
};

export default AdminOrBorrowerGuard;