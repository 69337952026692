import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios';

const initialState = {
  promNotes: [],
  lenderBids: [],
  promNoteOptions: [],
  promNote: {},
  count: 0,
  isModalOpen: false,
  selectedNoteId: null,
  selectedRange: null,
  isSubmitted: false,
  error: null
};

const slice = createSlice({
  name: 'promNote',
  initialState,
  reducers: {
    getNotes(state, action) {
      const { promNotes, count, lenderBids } = action.payload;
      state.promNote = {};
      state.promNotes = promNotes;
      state.lenderBids = lenderBids;
      state.count = count;
    },
    getNoteOptions(state, action) {
      state.promNoteOptions = action.payload;
    },
    getNote(state, action) {
      const promNote = action.payload;

      state.promNote = promNote;
    },
    createNote(state, action) {
      const promNote = action.payload;

      state.promNotes = [...state.promNotes, promNote];
      state.promNote = promNote;
    },
    updateNote(state, action) {
      const promNote = action.payload;

      state.promNotes = _.map(state.promNotes, (_promNote) => {
        if (_promNote._id === promNote._id) {
          return promNote;
        }

        return _promNote;
      });
      state.promNote = promNote;
    },
    deleteNote(state, action) {
      const { promNoteId } = action.payload;

      state.promNotes = _.reject(state.promNotes, { _id: promNoteId });
    },
    createBid(state, action) {
      const promNote = action.payload;

      state.promNotes = [...state.promNotes, promNote];
      state.promNote = promNote;
      state.isSubmitted = true
    },
    addToError(state, action) {
      state.error = action.payload
    },
    closeModel(state, action) {
      state.error = null;
      state.isSubmitted = false
    }
  }
});

export const reducer = slice.reducer;

export const getNotes = (page, limit, data) => async (dispatch) => {
  const response = await axios.get(
    `/promNotes?page=${page}&perPage=${limit}`
    // `/promNotes`
  );
  dispatch(slice.actions.getNotes(response.data));
};

export const getNote = (id) => async (dispatch) => {
  const response = await axios.get(`/promNotes/${id}`);
  console.log("helloooo3333", response)
  dispatch(slice.actions.getNote(response.data));
};

export const createNote = (data) => async (dispatch) => {
  const response = await axios.post('/promNotes', data);

  await dispatch(slice.actions.createNote(response.data));

  return response.data
};

export const updateNote = (promNoteId, update) => async (dispatch) => {
  const response = await axios.put('/promNotes/' + promNoteId, update);

  await dispatch(slice.actions.updateNote(response.data));

  return response.data
};

export const deleteNote = (promNoteId) => async (dispatch) => {
  await axios.delete('/promNotes/' + promNoteId, {});

  dispatch(slice.actions.deleteNote({ promNoteId }));
};

export const closeModel = () => (dispatch) => {
  dispatch(slice.actions.closeModel())
}

export const createBid = (promNoteId, data) => async (dispatch) => {
  await axios.post(`/promNotes/${promNoteId}/createBid`, data)
    .then(async (response) => {
      await dispatch(slice.actions.createBid(response.data))
      await dispatch(slice.actions.addToError(null))
      return response.data
    })
    .catch(async (err) => {
      await dispatch(slice.actions.addToError(err.message));
    })
  // console.log("hellooooooo", response)
  // await dispatch(slice.actions.createBid(response.data));
  // return response.data
};

export const getNoteOptions = () => async (dispatch) => {
  const response = await axios.get(`/promNotes/as-options`);

  dispatch(slice.actions.getNoteOptions(response.data));
};

export default slice;
