import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'src/utils/axios';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  userType: null,
  config: null,
  actions: {},
  sections: {},
  user: null
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  return true
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;
      var userType = user ? user.userType : state.userType

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        userType,
        user
      };
    }
    case 'CONFIG': {
      const { config } = action.payload;

      return {
        state,
        userType: config.userTypes.STAFF_MEMBER,
        actions: config.actions,
        sections: config.sections,
        config
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        userType: user?.userType,
        user
      };
    }
    case 'UPDATE': {
      const { user } = action.payload;
      state.user = user

      return {
        ...state,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  validate2fa: () => Promise.resolve(),
  activate2fa: () => Promise.resolve(),
  verify2fa: () => Promise.resolve(),
  saveToken: () => Promise.resolve(),
  forgot: () => Promise.resolve(),
  reset: () => Promise.resolve(),
  setConfig: () => Promise.resolve(),
  profileUpdate: () => { },
  logout: () => { },
  canAccess: () => { },
  isAdmin: () => { },
  isLender: () => { },
  isBorrower: () => { },
  register: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (email, password) => {
    var response = await axios.post('/login', { email, password });
    const { token } = response.data;

    return await saveToken(token);
  };

  const activate2fa = async () => {
    console.log("helloooooooo111")
    var response = await axios.post('/users/activate-2fa', {});
    const { secret } = response.data;
    console.log("helloooooooo", secret)
    return secret;
  };

  const verify2fa = async (token) => {
    var response = await axios.post('/users/verify-2fa', { token });
    const { verified } = response.data;

    return verified;
  };

  const validate2fa = async (email, twofa) => {
    var response = await axios.post('/validate-2fa', { email, token: twofa });
    const { token } = response.data;

    return await saveToken(token);
  };

  async function saveToken(token) {
    console.log("helloooo", token)
    setSession(token);
    var response = await axios.get('/profile');
    dispatch({
      type: 'LOGIN',
      payload: {
        user: response.data
      }
    });
    return response;
  }

  const forgot = async ({ email, agencyId }) => {
    return await axios.post('/auth/recover', { userType: state.userType, email });
  };

  const reset = async (token, data) => {
    return await axios.post('/auth/reset/' + token, { userType: state.userType, ...data });
  };

  const setConfig = async () => {
    if (state.config) {
      return true
    }
    var response = await axios.get('/config');
    const config = response.data.data;

    dispatch({
      type: 'CONFIG',
      payload: {
        config
      }
    });
  };

  const profileUpdate = async (user, update = true) => {
    if (update) {
      user = (await axios.put('/users', user)).data;
    }

    dispatch({
      type: 'UPDATE',
      payload: {
        user
      }
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const canAccess = (section, action) => {
    if (!state.user || !action || !section)
      return false

    if (state.user.superUser)
      return true

    return state.user.role.permissions[section][action]
  };

  const isAdmin = () => {
    return state.userType === 'Admin'
  };
  const isLender = () => {
    return state.userType === 'Lender'
  };
  const isBorrower = () => {
    return state.userType === 'Borrower'
  };

  const register = async (email, name, password) => {
    const response = await axios.post('/api/account/register', {
      email,
      name,
      password
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  useEffect(() => {
    const initialise = async () => {
      // await setConfig()

      try {

        const accessToken = window.localStorage.getItem('accessToken');
        console.log("hellooooo", accessToken)
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get('/profile');
          console.log("hellooooo", response.data)
          const user = response.data;

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  if (state.isAuthenticated && !state.user) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        validate2fa,
        activate2fa,
        verify2fa,
        saveToken,
        forgot,
        reset,
        setConfig,
        logout,
        canAccess,
        isAdmin,
        isLender,
        isBorrower,
        profileUpdate,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
