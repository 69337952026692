import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const AdminGuard = ({ children }) => {
  const { isAdmin } = useAuth();
  console.log("helloooo2", isAdmin)

  if (!isAdmin()) {
    return <Redirect to="/app/dashboard" />;
  }

  return (
    <>
      {children}
    </>
  );
};

AdminGuard.propTypes = {
  children: PropTypes.node
};

export default AdminGuard;
